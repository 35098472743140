import { DrugCostOutput, FrequencyPeriod, MonthlyTotalCost } from '@coverright/data-access/types/medicare';
import { Box, Collapse, Divider, styled, Typography, useMediaQuery, useTheme } from '@mui/material';
import * as React from 'react';
import { useMemo } from 'react';
import { CaretDown } from '@phosphor-icons/react';
import * as _ from 'lodash';
import moment from 'moment';
import { MedigapFrequencyPeriodOutput } from '@coverright/data-access/types/medigap';
import { DrugPriceOutput } from '@coverright/data-access/medicare';

export const DrugSummaryLegacy = ({
  costs,
  total,
  monthlyCosts,
  preferredDrugs,
  drugPrices,
}: {
  costs: DrugCostOutput[];
  monthlyCosts?: MonthlyTotalCost[];
  drugPrices: DrugPriceOutput[];
  total?: string;
  preferredDrugs?: {
    packRxcui: string;
    purchaseFrequency: FrequencyPeriod | MedigapFrequencyPeriodOutput;
  }[];
}) => {
  const theme = useTheme();
  const phone = useMediaQuery(theme.breakpoints.down('md'));

  const groupedCosts = Object.values(_.groupBy(costs, 'month')).map((g) =>
    g.map((c) => {
      const drug = preferredDrugs?.find((d) => d.packRxcui === c.rxcui);
      const qty =
        drugPrices.find((d) => d.rxcui === c.rxcui)?.quoteQuantity || '';

      let purchaseFrequencyMonthNumber: string;

      switch (drug?.purchaseFrequency) {
        case FrequencyPeriod.Quarterly: purchaseFrequencyMonthNumber = '3 '; break;
        case FrequencyPeriod.HalfYearly: purchaseFrequencyMonthNumber = '6 '; break;
        case FrequencyPeriod.Yearly: purchaseFrequencyMonthNumber = '12 '; break;
        default: purchaseFrequencyMonthNumber = '';
      }

      const purchaseFrequency = drug ? `Every ${purchaseFrequencyMonthNumber}month` : '';

      return {
        ...c,
        qty,
        frequency: purchaseFrequency,
        packageDescription: drugPrices.find((p) => p.rxcui === c.rxcui)
          ?.packageDescription,
      };
    })
  );

  const mobileData = useMemo(
    () =>
      drugPrices.map((price) => ({
        title: price.name,
        data: [
          {
            label: 'Tier',
            value: price.tier?.toString() || '',
          },
          {
            label: 'Deductible',
            value: `${price.price} / ${price.unitPrice} ${
              price.isAggregatedPrice ? '*' : ''
            }`,
          },
          {
            label: 'Initial coverage',
            value: price.initialCoveragePrice,
          },
          {
            label: 'Coverage gap ("Donut hole")',
            value: price.coverageGapPrice,
          },
          {
            label: 'Catastrophic coverage',
            value: price.catastrophicPrice,
          },
        ],
      })),
    [drugPrices]
  );

  return phone ? (
    <Mobile data={mobileData} />
  ) : (
    <Desktop
      groupedCosts={groupedCosts}
      monthlyCosts={monthlyCosts}
      totalClientCost={total}
    />
  );
};

type GroupedCosts = Array<
  DrugCostOutput & {
    qty: string;
    frequency: string;
    packageDescription?: string;
  }
>;

interface DesktopProps {
  totalClientCost?: string;
  groupedCosts: GroupedCosts[];
  monthlyCosts?: MonthlyTotalCost[];
}

const Desktop = ({
  totalClientCost,
  groupedCosts,
  monthlyCosts,
}: DesktopProps) => {
  const [expandedState, setExpandedState] = React.useState<{
    [key: string]: boolean;
  }>({});

  const toggleMonth = (monthName: string) => {
    setExpandedState((prevState) => ({
      ...prevState,
      [monthName]:
        typeof prevState[monthName] === 'undefined'
          ? true
          : !prevState[monthName],
    }));
  };

  return (
    <div>
      <Table cellSpacing={0}>
        <thead>
          <tr>
            <td colSpan={2}>Your summary by month</td>
            <td>Qty</td>
            <td>Purchase frequency</td>
            <td>Monthly cost</td>
            <td>Coverage level</td>
          </tr>
        </thead>
      </Table>
      {groupedCosts?.map((groupedByMonthCosts, i) => {
        const month = groupedByMonthCosts[0].month;
        const monthName = moment(month.toString(), 'M').format('MMMM');
        return (
          <MonthTable
            key={i}
            onClick={() => toggleMonth(monthName)}
            expanded={!!expandedState[monthName]}
            costs={groupedByMonthCosts}
            monthName={monthName}
            monthlyCost={monthlyCosts?.find((mc) => mc.month == month)}
          />
        );
      })}
      {/*<Table className={`${classes.summaryByMonthTable}`}} cellSpacing={0}>*/}
      <Table cellSpacing={0}>
        <thead>
          {/*<tr className={`${classes.titleRow} ${classes.summaryByMonthTitleRow}`}>*/}
          <tr>
            <td colSpan={2}>Total Annual Cost</td>
            <td></td>
            <td></td>
            <td>{totalClientCost}</td>
            <td></td>
          </tr>
        </thead>
      </Table>
    </div>
  );
};

interface MonthTableProps {
  costs: GroupedCosts;
  monthlyCost?: MonthlyTotalCost;
  monthName: string;
  expanded: boolean;
  onClick: () => void;
}

const MonthTable = (props: MonthTableProps) => {
  const { costs, monthlyCost, monthName, expanded, onClick } = props;
  return (
    <>
      <Table style={{ fontWeight: 500 }} cellSpacing={0}>
        <tbody>
          <tr className={'pointer'} onClick={onClick}>
            <td colSpan={2}>
              <Box
                component={'span'}
                sx={{
                  transform: `rotate(${expanded ? 0 : '-180deg'})`,
                  transition: 'all 0.3s',
                  marginRight: 1,
                  marginTop: 1,
                }}
              >
                <CaretDown
                  size={12}
                  color="#1C434F"
                  weight="bold"
                  onClick={onClick}
                />
              </Box>
              {monthName}
            </td>
            <td></td>
            <td></td>
            <td> {monthlyCost?.amountString} </td>
            <td> {_.upperFirst(_.lowerCase(monthlyCost?.stage)) || ''} </td>
          </tr>
        </tbody>
      </Table>
      <Collapse in={expanded}>
        <Table cellSpacing={0}>
          <tbody>
            {costs.map((cost) => (
              <tr key={`${cost.month}-${cost.name}`}>
                <td style={{ paddingLeft: 40 }} colSpan={2}>
                  {cost.packageDescription}
                </td>
                <td>{cost.qty}</td>
                <td>{cost.frequency}</td>
                <td>{cost.cost}</td>
                <td></td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Collapse>
    </>
  );
};

const Table = styled('table')({
  tableLayout: 'fixed',
  width: '100%',
  '& thead': {
    backgroundColor: '#F7FAFE',
    fontWeight: 600,
  },
  '& td': {
    borderBottom: '1px solid #E8ECED',
    paddingTop: 16,
    paddingBottom: 16,
    fontSize: 12,
    color: '#1C434F',
    paddingLeft: 16,
  },
  '& tr td:last-child': {
    paddingRight: 16,
  },
});

// -------- Mobile ---------------------------

const Mobile = ({
  data,
}: {
  data: { title: string; data: { label: string; value: string }[] }[];
}) => {
  return (
    <>
      {data.map((item) => (
        <Section data={item} />
      ))}
    </>
  );
};

const HeaderRow = ({
  title,
  onClick,
  open,
}: {
  title: string;
  onClick: () => void;
  open: boolean;
}) => {
  return (
    <>
      <Box className={'flex-space-between flex-align-center'} py={'9px'}>
        <Typography className={'bold fs-12'} color={'textPrimary'}>
          {title}
        </Typography>
        <Box
          sx={{
            transform: `rotate(${open ? 0 : '-180deg'})`,
            transition: 'all 0.3s',
            marginRight: 15,
            marginTop: 1,
          }}
        >
          <CaretDown
            size={12}
            color="#1C434F"
            weight="bold"
            onClick={onClick}
          />
        </Box>
      </Box>
      <Divider />
    </>
  );
};

const Row = (props: { label: string; value: string }) => {
  return (
    <>
      <Box className={'flex flex-align-center'} py={'9px'}>
        <Box display={'flex'} flex={1}>
          <Typography className={'fs-12'} color={'textPrimary'}>
            {props.label}
          </Typography>
        </Box>
        <Box display={'flex'} flex={1} ml={1}>
          <Typography className={'fs-12'} color={'textPrimary'}>
            <i>{props.value}</i>
          </Typography>
        </Box>
      </Box>
      <Divider />
    </>
  );
};

const Section = ({
  data,
}: {
  data: { title: string; data: { label: string; value: string }[] };
}) => {
  const [open, setOpen] = React.useState(false);
  return (
    <>
      <HeaderRow
        title={data.title}
        onClick={() => setOpen(!open)}
        open={!open}
      />
      <Collapse in={!open}>
        {data.data.map((item) => (
          <Row key={item.value} value={item.value} label={item.label} />
        ))}
      </Collapse>
    </>
  );
};
