import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  ChartData,
  ChartMeta,
  ChartOptions,
  Legend,
  LegendItem,
  LinearScale,
  LogarithmicScale,
  Title,
  Tooltip,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import * as _ from 'lodash';
import {
  DrugCostOutput,
  MonthlyTotalCost,
} from '@coverright/data-access/types/medicare';
import * as React from 'react';
import { V3Preloader } from '@coverright/ui/shared';
import * as moment from 'moment';
import { Box, SxProps } from '@mui/material';
import { getChartCoverageAreas } from '@coverright/utils';
import { useIsV3Theme } from '@coverright/features';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  LogarithmicScale
);

const getOptions: (font: string) => ChartOptions<'bar'> = (font) => ({
  plugins: {
    legend: {
      align: 'start',
      position: 'bottom',
      labels: {
        boxWidth: 10,
        boxHeight: 10,
        /*generateLabels(chart: Chart): LegendItem[] {
          console.log(chart?.legend?.legendItems);
          return chart?.legend?.legendItems?.map(i => ({
            ...i,
            fontColor: i.fillStyle,
            borderRadius: 5
          })) || []
        },*/
        font: {
          family: font,
          size: 14,
          lineHeight: '24px',
          weight: '600',
        },
      },
    },
  },
  font: {
    family: font,
    size: 16,
    lineHeight: '17px',
    weight: '500',
  },
  responsive: true,
  color: '#1C434F',
  scales: {
    x: {
      stacked: true,
      grid: {
        display: false,
      },
      ticks: {
        font: {
          family: font,
          size: 16,
          lineHeight: '17px',
          weight: '500',
        },
        color: '#1C434F',
      },
    },
    y: {
      stacked: true,
      grace: '50%',
      grid: {
        display: false,
        drawBorder: false,
      },
      ticks: {
        display: false,
      },
    },
  },
});

const chartColors = [
  '#00BBCC',
  '#038995',
  '#005D65',
  '#F50B0B',
  '#C10303',
  '#FA73F4',
  '#AC37AF',
  '#478FFA',
  '#0853C3',
  '#5EAA0B',
  '#03C425',
  '#386A00',
  '#F38505',
  '#C46003',
  '#4F2601',
];

type PlanSummaryChartProps = {
  costs: DrugCostOutput[];
  monthlyCosts: MonthlyTotalCost[];
  loading?: boolean;
  colors?: string[];
  variant?: 'legacy' | 'v3' | 'z1';
  wrapperSx?: SxProps;
  height?: number;
};

export function Chart({
  costs,
  monthlyCosts,
  loading,
  colors = chartColors,
  variant = 'legacy',
  wrapperSx = { display: { xs: 'none', sm: 'block' } },
  height
}: PlanSummaryChartProps) {
  const font = variant === 'legacy' ? 'Sailec' : 'Inter';

  const [chartData, setChartData] = React.useState<ChartData<'bar'>>({
    datasets: [],
  });

  const getColor = (index: number): string =>
    index >= colors.length ? getColor(index - colors.length) : colors[index];

  React.useEffect(() => {
    sessionStorage.removeItem('monthlyCosts');
  }, []);

  const barLabelPlugin = React.useMemo(() => {
    const monthCount = monthlyCosts?.length;
    if (monthCount) {
      return {
        id: 'custom_canvas_bar_label',
        afterDraw: (chart: ChartJS) => {
          const metas = chart.data.datasets.map((d, i) =>
            chart.getDatasetMeta(i)
          );
          if (!metas.length) {
            return;
          }
          const ctx = chart.ctx;

          ctx.font = `bold 14px ${font}`;
          ctx.textAlign = 'center';
          ctx.textBaseline = 'bottom';
          const monthsCount =
            _.max(chart.data.datasets.map((d) => d.data.length)) || 0;

          const longestBarsMeta: ChartMeta = metas.reduce(
            (previousValue: ChartMeta, currentValue: ChartMeta) => {
              if (!previousValue) {
                return currentValue;
              } else {
                return previousValue.data.length < currentValue.data.length
                  ? currentValue
                  : previousValue;
              }
            }
          );

          const barHeights: number[] = [];

          for (let i = 0; i < monthsCount; i++) {
            let height = 0;
            metas.forEach((meta: any) => {
              height += meta.data[i]?.height || 0;
            });
            barHeights[i] = height;
          }

          const getBarValue = (index: number): number => {
            let result = 0;
            chart.data.datasets.forEach((d) => {
              result += d.data[index] as number;
            });
            return Math.round(result);
          };

          barHeights.forEach((h, index) => {
            ctx.fillStyle = '#1C434F'; // label color
            ctx.lineWidth = 2;
            const label = '$' + getBarValue(index);
            if (barHeights[index]) {
              ctx.fillText(
                label,
                longestBarsMeta.data[index].x,
                chart.chartArea.height - barHeights[index] - 2
              );
            }
          });
        },
      };
    }
    return { id: 'custom_canvas_bar_label' };
  }, [monthlyCosts?.length]);

  React.useEffect(() => {
    const groupedCosts = Object.values(_.groupBy(costs, 'rxcui'));

    if (groupedCosts.length) {
      sessionStorage.setItem('monthlyCosts', JSON.stringify(monthlyCosts));
      const labels = groupedCosts.reduce((prev: any[], curr: any[]) => {
        if (prev.length > curr.length) {
          return prev;
        }
        return curr;
      }, [] as any);

      setChartData({
        labels: labels
          .map((c) => c.month)
          .map((month) =>
            (moment as any)()
              .set({ month: month - 1 })
              .format('MMM')
          ),
        datasets: groupedCosts.map((costArr: DrugCostOutput[], i: number) => ({
          label: costArr[0]?.packageName,
          data: labels.map((c) =>
            parseFloat(
              costArr
                .find((co) => co.month === c.month)
                ?.cost.replace('$', '')
                .replace(',', '') || '0'
            )
          ),
          backgroundColor: getColor(i),
        })),
      });
    }
  }, [costs, monthlyCosts]);

  const backgroundPlugin = React.useMemo(() => {
    const monthCount =
      (_.max(costs.map((c) => c.month)) || 0) -
      (_.min(costs.map((c) => c.month)) || 0) +
      1;
    if (monthCount) {
      return {
        id: 'custom_canvas_background_color',
        beforeDraw: (chart: ChartJS) => {
          const ctx = chart.ctx;
          const canvas = chart.canvas;
          const chartArea = chart.chartArea;

          // Chart background
          const gradientBack = canvas
            .getContext('2d')
            ?.createLinearGradient(chartArea.left, 0, chartArea.right, 0);
          if (gradientBack) {
            const monthlyCosts = JSON.parse(
              sessionStorage.getItem('monthlyCosts') || '[]'
            );

            const monthCount = monthlyCosts?.length;

            // const areas = getAreas(monthlyCosts, sessionStorage.getItem('fullYear') === 'true');
            const areas = getChartCoverageAreas(monthlyCosts);

            if (areas.length) {
              gradientBack.addColorStop(0, areas[0].color);
              let accMonth = 0;
              areas.forEach((area, index) => {
                if (index < areas.length - 1) {
                  const nextArea = areas[index + 1];
                  const length = monthlyCosts.filter(
                    (c: MonthlyTotalCost) => c.stage === area.stage
                  ).length;
                  accMonth += length;
                  gradientBack.addColorStop(accMonth / monthCount, area.color);
                  gradientBack.addColorStop(
                    accMonth / monthCount,
                    nextArea.color
                  );
                } else {
                  gradientBack.addColorStop(1, areas[index].color);
                }
              });

              ctx.fillStyle = gradientBack;
              ctx.fillRect(
                chartArea.left,
                chartArea.bottom,
                chartArea.right - chartArea.left,
                chartArea.top - chartArea.bottom
              );

              ctx.fillStyle = '#1C434F'; // label color
              ctx.lineWidth = 2;
              const fillText = (
                text: string,
                center: number,
                linesSplit: boolean
              ) => {
                if (linesSplit) {
                  const splitted = text.split(' ');
                  ctx.fillText(splitted[0], center, 30);
                  ctx.fillText(splitted[1], center, 50);
                } else {
                  ctx.fillText(text, center, 30);
                }
              };
              accMonth = 0;
              areas.forEach((area, index) => {
                const nextArea = areas[index + 1];
                const length = nextArea
                  ? nextArea?.startMonth - area.startMonth
                  : monthCount - accMonth;
                const barWidth =
                  (chartArea.right - chartArea.left) / monthCount;
                const center =
                  chartArea.left +
                  accMonth * barWidth +
                  (barWidth * length) / 2;
                accMonth += length;
                fillText(
                  area.text,
                  center,
                  (area?.split && area?.split(length)) || false
                );
              });
            }
          }
        },
      };
    }
    return { id: 'custom_canvas_background_color' };
  }, [monthlyCosts.length]);

  if (!monthlyCosts.length || loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', width: 1 }}>
        <V3Preloader />
      </Box>
    );
  }

  return (
    <Box sx={wrapperSx}>
      <Bar
        options={getOptions(font)}
        data={chartData}
        plugins={[barLabelPlugin, backgroundPlugin]}
        height={height}
      />
    </Box>
  );
}
