import { useMemo } from 'react';
import { GetNextPlansYear, GetDefaultPlansYear, GetPlansYear } from '@coverright/utils';
import { PlanYear } from '@coverright/data-access/types/medicare';

export default function usePlanYear(enrolledPlanYear?: PlanYear) {
  return useMemo(() => {
    if (enrolledPlanYear) {
      return [GetPlansYear(), GetNextPlansYear()].includes(enrolledPlanYear)
        ? enrolledPlanYear
        : GetDefaultPlansYear();
    }
    return GetDefaultPlansYear();
  }, [enrolledPlanYear]);
}
